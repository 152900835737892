import React from 'react';
import Placeholder from '../../../core/Components/Placeholder';

const FullWidthContainer = ({rendering}) => {
	return (
		<div className="sl l-padding is-full-width">
			<Placeholder name="content-full-width" rendering={rendering} />
		</div>
	);
};

export default FullWidthContainer;
